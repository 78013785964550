import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// @ts-ignore
import { formatPrice, getStoreName } from './shared.tsx';
// @ts-ignore
import { PurchaseCheckbox } from './PurchaseCheckbox.tsx';



const GiftTable = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Purchase</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Store</TableCell>
            <TableCell align="right">Price</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {props.gifts?.map((row) => (
            <TableRow
              key={row.giftId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center"><PurchaseCheckbox id={row.giftId} name={row.giftName} purchased={row.giftPurchased} fetchCallback={props.fetchCallback} /></TableCell>
              <TableCell align="right"><a href={row.giftUrl} target="_blank" rel="nofollow"><img src={row.giftImage} height={100} /></a></TableCell>
              <TableCell component="th" scope="row">
                <a href={row.giftUrl} target="_blank" rel="nofollow">{row.giftName}</a>
              </TableCell>
              <TableCell>{getStoreName(row.giftUrl)}</TableCell>
              <TableCell align="right">{formatPrice(row.giftPrice)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GiftTable;