
import logo from './logo.svg';
import './App.css';
import GiftTable from "./GiftTable.tsx";
import { Container } from "@mui/material";
import { ButtonAppBar } from "./Header.tsx";
import { useEffect, useState } from "react";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const GiftItem = (props) => {
  return <div>
    <a href={props.giftUrl}><h3>{props.giftName}</h3></a>
  </div>
};

const fetchData = (setData, setLoading) => {
  fetch("https://wilsongifts-worker.wilson.workers.dev/api/gifts")
  .then(response => response.json())
  .then(response => {
    setLoading(false);
    setData(response);
  })
}

function App() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSelfData = () => {
    fetchData(setData, setLoading);
  };

  useEffect(() => {
    fetchSelfData();
  }, []);

  

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <ButtonAppBar />
        <main>
          {isLoading ? <p style={{textAlign: 'center'}}>Loading...</p> : <GiftTable gifts={data} fetchCallback={fetchSelfData} />}
        </main>
      </Container>
    </ThemeProvider>
  );
}

export default App;
