


export const formatPrice  = (price : string) : string => {
    return `$${parseFloat(price).toFixed(2)}`;
};


const extractDomain = (url : string) : any[string] => {
    const matches = url.split(/https?:\/\/([A-z0-9\.]+)(.*)/ig);
    if (matches.length > 1) {
        return matches[1].replace('www.', '');
    }
}


export const getStoreName = (url : string) : string => {

    switch(extractDomain(url)) {
        case 'amazon.com':
            return 'Amazon';
        case 'homedepot.com':
            return 'Home Depot';
        case 'apple.com':
            return 'Apple';
    }

    return '';
}