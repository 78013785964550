import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';


const clickCheckbox = (id : string, fetchCallback, disabledCallback) : void => {
  disabledCallback(true);
  fetch('https://wilsongifts-worker.wilson.workers.dev/api/gifts/purchase', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "id": id })
  })
  .then(response => response.json())
  .then(response => {
    fetchCallback();
    disabledCallback(false);
    return response.status === 'ok'
  });
};

export const PurchaseCheckbox = (props) => {
  const label = { inputProps: { 'aria-label': `Mark ${props.name} as purchased` } };
  const [disabled, setDisabled] = React.useState(false);
  return (
    <div>
      <Checkbox {...label} checked={!!props.purchased} disabled={disabled} onClick={() => clickCheckbox(props.id, props.fetchCallback, setDisabled)} />
    </div>
  );
}
